import * as React from 'react';
import { graphql } from 'gatsby';

// components
import Seo from '../components/seo/SEO';

// utils
import { renderSwitch } from '../utils/Render';

const AboutPage = ({ data: { page }, location }) => {
    const { seo } = page;
    const keywords = [];
    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }
    const content = page.contentMatrix;

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
                url={location.href}
            />
            <div>{content.map((block, i) => renderSwitch(block, i))}</div>
        </>
    );
};

export const query = graphql`
    {
        page: craftAboutAboutEntry {
            title
            uri
            ... on Craft_about_about_Entry {
                id
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                    }
                }
                dateUpdated
                contentMatrix {
                    ...RichTextFragment
                    ...ProductsBlockFragment
                    ...TextGridBlockFragment
                    ...CalloutCtaBlockFragment
                    ...LargeCalloutBlockFragment
                    ...RainyDaysCalloutFragment
                    ...SingleMediaFragment
                    ...TextAndImageGridBlockFragment
                    ...ThumbnailImageGridFragment
                    ...TwoUpImageBlockFragment
                    ...ImageCarouselFragment
                    ...TextAndImageCtaBlockFragment
                    ...NewsletterSignupFragment
                    ...TextImageBlockFragment
                    ...PressBlockFragment
                    ...BannerBlockFragment
                    ...TimelineBlockFragment
                    ...FigureLinkGridFragment
                    ...ContactFormFragment
                    ...CloudGraphic
                    ...RainGraphic
                    ...StoremapperEmbed
                }
            }
        }
    }
`;

export default AboutPage;
